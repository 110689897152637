import React from "react"
import { Helmet } from "react-helmet"
import KdpHeader from "../blocs/Header/Header"
import KdpFooter from "../blocs/Footer/Footer"
import KdpAriane from "../blocs/Ariane"
import KdpReseaux from "../blocs/Reseaux"

const ariane = [
  { name: 'Réseaux', href: '', current: true },
]

export default function Reseaux() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Réseaux de CEOS Avocats</title>
        <meta name="description" content="Sur les plans technique (médecins, architectes et ingénieurs) et juridique (cabinets d’avocats et huissiers de justice)" />
      </Helmet>
      <header>
        <KdpHeader />
      </header>
      <main>
        <KdpAriane 
          filariane={ariane}
        />
        



        <KdpReseaux />




      </main>
      <footer>
      <KdpFooter />
      </footer>
    </div>
  );
}