import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

/**
 * 
 * aspect-w-12 
 */

export default function Reseaux() {
  return (
    <div className="bg-gray-50 overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4 bg bg-[url('../images/ceos-pattern.png')] grayscale brightness-200 opacity-20"
              width={404}
              fill="none"
              viewBox="0 0 404 4000"
              aria-hidden="true"
            >
            </svg>
            <div>
              <h1 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Réseaux
              </h1>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-h-7 lg:aspect-none">
                  <StaticImage 
                    className=" object-cover object-center"
                    src="../images/carte-ceos.png" 
                    alt="Ceos Bureau"
                    width={626}
                  />
                  </div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <div className="text-base max-w-prose mx-auto lg:max-w-none">
                <p className="text-gray-500 text-base leading-7 text-justify">
                Le cabinet Céos Avocats a développé un réseau informel de partenaires pluridisciplinaires aux compétences complémentaires aux siennes afin de fournir à ses clients une prestation juridique et expertale complète.
                </p>
              </div>
              <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p>
                Il concerte et coordonne ses actions : 
                </p>
                <h2 className="text-justify">Sur le plan technique, avec des experts judiciaires inscrits près les Cours d’Appel :</h2>
                <ul>
                  <li className="text-justify">des médecins, pour l’évaluation des taux d’incapacité permanente partielle, des incapacités temporaires totales, des conséquences indemnitaires de la faute inexcusable de l’employeur, et plus généralement des séquelles d’accidents et de maladies.</li>
                  <li className="text-justify">des architectes et ingénieurs, pour l’analyse et l’évaluation des dommages constructions, la mise en œuvre des garanties d’assurances, l’étude de la nocivité d’une substance ou d’un procédé technique sur la santé. </li>
                </ul>
                <h2 className="text-justify">Sur le plan juridique, avec des professionnels du droit&nbsp;: </h2>
                <ul>
                  <li className="text-justify">des cabinets d’avocats, implantés sur l’ensemble du territoire, pour garantir une représentation judiciaire en tous lieux, une connaissance des pratiques judiciaires locales et une expertise juridique dans des domaines d’interventions parallèles aux siens.</li>
                  <li className="text-justify">des huissiers de justice pour sauvegarder les intérêts qui lui sont confiés et pour garantir la bonne exécution des décisions de Justice.</li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
